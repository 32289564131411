import React from "react"

import { footlink, footnote } from "../shared/links"

import styles from "../../styles/main.module.css"

const footnotes = [
    "Paseamos por Barcelona, Lisboa, y Canarias con la abuela, ya que en unos años no sabemos si estará bien para viajar.",
    "La compramos en una biblioteca re linda de las muchas que hay en Barcelona. Eso me recuerda, que cuando yo viva independientemente después de la pandemia creo que haré lo que me dijo Eric. Cuando vivió en Nueva York, cada viernes Eric pasaba por una librería y compraba un libro interesante a la zar para leerlo esa semana siguiente. Es un poco romántico, pero me parece divertido, y quizás así exploraré áreas técnicas diferentes como topología, combinatoria, física, etcétera. Seguro que me lo pasaría leyendo historia y novelas, igual.",
    "Muchas veces ni lo leía (o por lo menos, no a fondo) ya que se encuentran resúmenes online, y que Gabriela aceptaba mis excusas. Yo era re vaguito.",
    "Hace uno años, me dediqué a aprender japonés, aunque nunca terminé, y todavía no he visitado Japón.",
    "Barcelona, Alta California, las naves, los gitano, etcétera.",
    "Es todo sentido común, pero me ayuda porque yo me angustio y se me olvida todo cuando no se que hacer. De esta manera puedo establecer un sistema metódico para cuando no tenga ideas brillantes.",
    "También tengo varias ideas para proyectos. Por ejemplo, me gustaría escribir un compilador. Tendré que pensar en que sacrificar porque no puedo hacer todo!",
]

export default function ElZorro() {
    return (
        <article>
        <h2>El Zorro es Divertido</h2>
        <hr className={styles.top_seperator} />
        <section id="body-section">
            <p>Cada dos por tres trato de leer algo en castellano para que no se me escape el idioma. El año pasado, después de volver de España {footlink(1)}, me traje una traducción de la biografía de Magallanes escrita por Stefan Zweig {footlink(2)}. Me resultó interesante, que era novedoso para mi ya que hace años que no leía nada en castellano. Dejé después de tomar el examen AP al fin de décimo grado, dado que lo único que venía leyendo era lo que me requería mi tutora Gabriela y no me interesaba ni un pepino {footlink(3)}. Después de eso decidí tomar Alemán con un amigo y me volví a interesar en aprender otras lenguas {footlink(4)}, pero con la pandemia si hizo difícil y dejé. Por eso, desde entonces, para no tener la mente monótona de alguien monolingüe, vengo leyendo cositas en español por aquí y por allá. Hasta volví a leer un poco de Mafalda y de Asterix. Ahora que <i>por fin</i> volví a Cambridge, me traje <i>El Zorro, Comienza la leyenda</i> escrita por Isabel Allende, y estuvo muy divertido! Me gustó.</p>
            <p>En la novela, Allende recuenta la historia de la juventud del Zorro (el famoso espadachín de la justicia), desde que nace, hasta que vuelve a California a los veinte años. Es una historia más o menos típica de héroes y aventura, pero está bien escrita y me resultó muy divertida. Desde que nació Diego (el Zorro) no recuerdo ninguna parte aburrida. Aunque mucho se puede predecir, hay partes inesperadas. Además, una trama ingeniosa no es lo que le de el encanto al libro. Son las escenas vívidas que lo logran {footlink(5)}.</p>
            <p>En fin, no tengo tantísimo que decir sobre el libro pero lo recomiendo. Es fácil de leer y divierte. Espero escribir más en castellano en el futuro, aunque ahora creo que me embarcaré en unos libros más técnicos (y en inglés). Ya empecé con el famoso <i>How to Solve It</i> de Pólya, como preparación {footlink(6)}. Después tengo libros de probabilidad y uno sobre Reinforcement Learning. También traje dos de Zen, y cuando termino mi pasantía con Nvidia pienso meditar {footlink(7)}. Escribiré sobre mis experiencias aquí (quizás en castellano).</p>
        </section>
        <section id="footnotes-section">
            {footnotes.map((note, index) => footnote(index + 1, note))}
        </section>
        <section>
            <div className={styles.phone_home}>
            <a href="/">Click me to return home.</a>
            </div>
        </section>
    </article>
    )
}
